import React from 'react';
import caretaker from '../../img/care_taker.png';
import stay from '../../img/stay.png';
import restaurant from '../../img/restaurant.png';
import cleaning from '../../img/cleaning.png';
import construction from '../../img/construction.png';
import ship from '../../img/shiip.png';
import car from '../../img/car.png';
import aviation from '../../img/aviation.png';
import agriculture from '../../img/agriculture.png';
import fishing from '../../img/fishing.png';
import food from '../../img/food.png';
import industry from '../../img/industry.png';

// Data for the Specified Skills section
const skillsData = [
    {
        id: 1,
        icon: caretaker,
        title: "Care Taker",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit."
    },
    {
        id: 2,
        icon: stay,
        title: "Stay",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit."
    },
    {
        id: 3,
        icon: restaurant,
        title: "Restaurant Business",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit."
    },
    {
        id: 4,
        icon: cleaning,
        title: "Building Cleaning",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit."
    },
    {
        id: 5,
        icon: construction,
        title: "Construction",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit."
    },
    {
        id: 6,
        icon: ship,
        title: "Ship Building and Marine Industry",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit."
    },
    {
        id: 7,
        icon: car,
        title: "Car Maintenance",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit."
    },
    {
        id: 8,
        icon: aviation,
        title: "Aviation",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit."
    },
    {
        id: 9,
        icon: agriculture,
        title: "Agriculture",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit."
    },
    {
        id: 10,
        icon: fishing,
        title: "Fishing",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit."
    },
    {
        id: 11,
        icon: food,
        title: "Food and Drink Manufacturing Industry",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit."
    },
    {
        id: 12,
        icon: industry,
        title: "Shapes, industrial machinery, electrical and electronic information related manufacturing industry",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit."
    }
];

function SpecifiedSkills() {
    return (
        <section id="customers">
            <div className="container">
                <div className="container-xxl py-5 category">
                    <div className="container">
                        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                            <h1 className="mb-5">Specified Skills</h1>
                        </div>
                    </div>
                    <div className="row mb-4 mt-6">
                        {skillsData.map((skill, index) => (
                            <div key={skill.id} className="col-md-6 col-lg-3 text-center">
                                <img src={skill.icon} alt={skill.title} style={{ width: "95px" }} />
                                <h4 className="mt-3 lh-base">{skill.title}</h4>
                                <p className="fs-0">{skill.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SpecifiedSkills;
