import React from 'react';
import BlogPost from './BlogPost'; // Import the BlogPost component
import blog1 from '../../img/blog/blog-1.jpg';
import blog2 from '../../img/blog/blog-2.jpg';
import blog3 from '../../img/blog/blog-3.jpg';
import blog4 from '../../img/blog/blog-4.jpg';
import blog5 from '../../img/blog/blog-5.jpg';
import blog6 from '../../img/blog/blog-6.jpg';

function BlogMain() {
    const blogPosts = [
        {
            image: blog1,
            date: 'December 12',
            title: 'Dolorum optio tempore voluptas dignissimos cumque fuga qui quibusdam quia',
            author: 'John Doe',
            category: 'Politics',
            excerpt: 'Similique neque nam consequuntur ad non maxime aliquam quas. Quibusdam animi praesentium. Aliquam et laboriosam eius aut nostrum quidem aliquid dicta.',
            link: 'blog-details.html'
        },
        {
            image: blog2,
            date: 'March 19',
            title: 'Nisi magni odit consequatur autem nulla dolorem',
            author: 'Julia Parker',
            category: 'Economics',
            excerpt: 'Incidunt voluptate sit temporibus aperiam. Quia vitae aut sint ullam quis illum voluptatum et. Quo libero rerum voluptatem pariatur nam.',
            link: 'blog-details.html'
        },
        // Add other blog posts here
        {
            image: blog3,
            date: 'June 24',
            title: 'Possimus soluta ut id suscipit ea ut. In quo quia et soluta libero sit sint.',
            author: 'Maria Doe',
            category: 'Sports',
            excerpt: 'Aut iste neque ut illum qui perspiciatis similique recusandae non. Fugit autem dolorem labore omnis et. Eum temporibus fugiat voluptate enim tenetur sunt omnis.',
            link: 'blog-details.html'
        },
        {
            image: blog4,
            date: 'August 05',
            title: 'Non rem rerum nam cum quo minus. Dolor distinctio deleniti explicabo eius exercitationem.',
            author: 'Maria Doe',
            category: 'Sports',
            excerpt: 'Aspernatur rerum perferendis et sint. Voluptates cupiditate voluptas atque quae. Rem veritatis rerum enim et autem. Saepe atque cum eligendi eaque iste omnis a qui.',
            link: 'blog-details.html'
        },
        {
            image: blog5,
            date: 'September 17',
            title: 'Accusamus quaerat aliquam qui debitis facilis consequatur',
            author: 'John Parker',
            category: 'Politics',
            excerpt: 'In itaque assumenda aliquam voluptatem qui temporibus iusto nisi quia. Autem vitae quas aperiam nesciunt mollitia tempora odio omnis. Ipsa odit sit ut amet necessitatibus. Quo ullam ut corrupti autem consequuntur totam dolorem.',
            link: 'blog-details.html'
        },
        {
            image: blog6,
            date: 'December 07',
            title: 'Distinctio provident quibusdam numquam aperiam aut',
            author: 'Julia White',
            category: 'Economics',
            excerpt: 'Expedita et temporibus eligendi enim molestiae est architecto praesentium dolores. Illo laboriosam officiis quis. Labore officia quia sit voluptatem nisi est dignissimos totam. Et voluptate et consectetur voluptatem id dolor magni impedit. Omnis dolores sit.',
            link: 'blog-details.html'
        }
    ];

    return (
        <main id="main">
            {/* Blog Section */}
            <section id="blog" className="blog">
                <div className="container" data-aos="fade-up" data-aos-delay="100">
                    <div className="row gy-4 posts-list">
                        {blogPosts.map((post, index) => (
                            <BlogPost
                                key={index}
                                image={post.image}
                                date={post.date}
                                title={post.title}
                                author={post.author}
                                category={post.category}
                                excerpt={post.excerpt}
                                link={post.link}
                            />
                        ))}
                    </div>
                    {/* Blog Pagination */}
                    <div className="blog-pagination">
                        <ul className="justify-content-center">
                            <li><a href="#">1</a></li>
                            <li className="active"><a href="#" style={{color: '#0A8096'}}>2</a></li>
                            <li><a href="#">3</a></li>
                        </ul>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default BlogMain;
