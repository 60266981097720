// CompanyMap.jsx
import React from 'react';
import '../../css/MapComponent.css';

function CompanyMap({ maps }) {
    return (
        <div className="container py-5">
            <div className="row justify-content-center">
                <div className="col-lg-12 text-center">
                    {maps.map((map, index) => (
                        <div key={index}>
                            <h1 className="accordion-body active alert alert-primary" style={{ borderRadius: "40px" }}>
                                {map.title}
                            </h1>
                            <div className="map-container mb-5">
                                <iframe
                                    className="rounded-border"
                                    src={map.url}
                                    allowFullScreen
                                    loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"
                                ></iframe>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default CompanyMap;
