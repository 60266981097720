import BreadCrumbs from "../components/BreadCrumbs";
import CompanyGreetings from "../components/CompanyProfileComponents/CompanyGreetings";
import CompanyMap from "../components/CompanyProfileComponents/CompanyMap";
import CompanyProfileComponents from "../components/CompanyProfileComponents/CompanyProfile";
import CompanyTeams from "../components/CompanyProfileComponents/CompanyTeams";
import Footer from "../components/Footer";
import Header from "../components/Header";
import IndexCompanyTeam from "../components/IndexComponents/IndexCompanyTeam";
import NavBar from "../components/NavBar";
import TopNavBar from "../components/TopNavBar";
import  wizimage from '../img/Wiz-Academy.png';
import team1 from '../img/team-1.jpg';
import team2 from '../img/team-2.jpg';
import team3 from '../img/team-3.jpg';
import team4 from '../img/team-4.jpg';



function CompanyProfilePage(){
    const title = "Company Profile";
    const breadcrumbItems = [
        { label: "Home", href: "#", isActive: false },
        { label: "Educational Services", href: "#", isActive: true }]


        const representativeTitle = "Representative";
        const heading = "Greetings";
        const introduction = "Thank you for visiting our website.";
        const companyDescription = `
            Wiz Global Co., Ltd. operates a foreign employment activity support school (Wiz Academy), 
            human resource service (Wiz job), and registration support service with the goal of solving 
            the problem of human resources shortage in Japan.
            After graduation of Master's Degree in my home country Nepal, I worked as a university teacher 
            for two years in my country and came to Japan for study abroad in December 2014. I have studied 
            psychology at the Faculty of Education. I got a chance to work at a staffing agency after 
            graduating from graduate school research at the International University of Health and Welfare. 
            I experienced and felt a big gap between foreign human resources and Japanese companies.
        `;
        const issues = [
            "Shortage of human resources in Japanese companies",
            "Delay of Japanese companies hiring foreigners",
            "Even getting a job at a Japanese company, foreigners cannot work for a long time.",
            "Differences in working styles between Japan and overseas"
        ];
        const conclusion = `
            I thought, I could contribute as much as possible to solve these problems, and established 
            the company ''Wiz Global'' in February 2021.
            We will do our utmost to achieve the satisfaction of all people involved in Wiz Global, including 
            our business partners, students, and human resources, as well as employees and their families.
            We look forward to your continued patronage in the future.
        `;
        const signature = `
            Wiz Global Co., Ltd.
            Representative (CEO)
            Paudel Om Narayan
        `;
        const contactLink = "contact.html";
        const lineLink = "LINE";





        const maps = [
            {
                title: "Our Location - Japan",
                url: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d59676202.39516496!2d66.9154983206899!3d24.08931299926291!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188dc0e3e83599%3A0x10d441146c549473!2sWiz%20Academy!5e0!3m2!1sen!2snp!4v1723401420838!5m2!1sen!2snp"
            },
            {
                title: "Our Location - Nepal",
                url: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3613348.9959787293!2d80.86075752685387!3d27.82019987983638!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb19a7b23cd139%3A0x3171f98ddb8859d0!2sKalika%20Marg%2C%20Kathmandu%2044600!5e0!3m2!1sen!2snp!4v1723401944783!5m2!1sen!2snp"
            }
        ];





        const companyData = {
            imageSrc: wizimage,
            companyName: 'Wiz Global Co., Ltd.',
            establishedDate: 'February 12, 2021',
            capital: '5,000,000 yen',
            academyLocations: [
                { city: 'Tokyo School', address: '169-0075 Sky Palace Building No. 802, 1-29-7 Takadanobaba, Shinjuku-ku, Tokyo', website: 'https://www.wizacademy.org' },
                { city: 'Nepal School', address: 'Kathmandu Municipality 29, Dillibazar, Kalika Marg 3F, Nepal', website: 'https://www.wizacademy.org' }
            ],
            businessContent: [
                'Education Service 「Wiz Academy」 (Employment Activity Support School for Foreign)',
                'Recruitment Service 「Wiz Job」 (Licence number: 13－ユ－313193)',
                'Registration support service (SSW Support Licence number: 22 登ー006871)',
                'Foreign language translation and interpretation services (English, Japanese, Nepali, Vietnamese, Sinhala, Hindi, etc.)',
            ],
            representative: 'Paudel Om Narayan',
            url: 'https://www.wizglobaljp.com',
            email: 'info@wizglobaljp.com',
            tel: '03-6709-6852',
            fax: '03-6709-6853',
        };
    



const teamMembers = [
    {
        imageSrc: team1,
        name: "Instructor 1",
        designation: "Designation 1",
        facebook: "https://facebook.com/instructor1",
        twitter: "https://twitter.com/instructor1",
        instagram: "https://instagram.com/instructor1"
    },
    {
        imageSrc: team2,
        name: "Instructor 2",
        designation: "Designation 2",
        facebook: "https://facebook.com/instructor2",
        twitter: "https://twitter.com/instructor2",
        instagram: "https://instagram.com/instructor2"
    },
    {
        imageSrc: team3,
        name: "Instructor 3",
        designation: "Designation 3",
        facebook: "https://facebook.com/instructor3",
        twitter: "https://twitter.com/instructor3",
        instagram: "https://instagram.com/instructor3"
    },
    {
        imageSrc: team4,
        name: "Instructor 4",
        designation: "Designation 4",
        facebook: "https://facebook.com/instructor4",
        twitter: "https://twitter.com/instructor4",
        instagram: "https://instagram.com/instructor4"
    }
];



    return(
        <>
        <TopNavBar />
        <NavBar />
        <BreadCrumbs title={title} breadcrumbItems={breadcrumbItems} />
        <CompanyProfileComponents {...companyData} />
        <CompanyMap maps={maps} />
    <IndexCompanyTeam teamMembers={teamMembers }/>
        <CompanyGreetings
            representativeTitle={representativeTitle}
            heading={heading}
            introduction={introduction}
            companyDescription={companyDescription}
            issues={issues}
            conclusion={conclusion}
            signature={signature}
            contactLink={contactLink}
            lineLink={lineLink}
        />
        <Footer />
</>
    )

}
export default CompanyProfilePage;