import BreadCrumbs from "../components/BreadCrumbs";
import Footer from "../components/Footer";
import Header from "../components/Header";
import NavBar from "../components/NavBar";
import CompanyIntro from "../components/ServiceComponents/CompanyIntro";
import CompanyStudent from "../components/ServiceComponents/CompanyStudents";
import Random from "../components/ServiceComponents/Random";
import TopNavBar from "../components/TopNavBar";

function CompanyServicePage(){
    const title = "Employment Activity Support School For Foreigners";
    const breadcrumbItems = [
        { label: "Home", href: "#", isActive: false },
        { label: "Educational Services", href: "#", isActive: true }]
    return(
        <>
        <TopNavBar />
        <NavBar />
       <BreadCrumbs title={title} breadcrumbItems={breadcrumbItems} />
        <CompanyIntro />
        <Random />
        <CompanyStudent />
        <Footer />
        </>
    )

}
export default CompanyServicePage;