import React from 'react';
import und1 from '../../img/und1.svg';
import job from '../../img/job.svg';
import interview from '../../img/interview.svg';
import visa from '../../img/visa.png';
import gtvisa from '../../img/gtvisa.png';
import company from '../../img/company.png';
import joboffer from '../../img/job_offer.png';

// Data for each step in the hiring process
const hiringSteps = [
    {
        id: '01',
        title: "Inquiries",
        description: "Please contact us from the inquiry form.",
        imgSrc: und1,
        link: "contact.html",
    },
    {
        id: '02',
        title: "Counseling & Hearing",
        description: "We will hear about the job information of companies and the image of human resources they are looking for so that mismatches do not occur.",
        imgSrc: job,
        link: "contact.html",
    },
    {
        id: '03',
        title: "Interview / Informal Decision",
        description: "We support not only local interviews but also web interviews. If you are interviewed, please give us a letter of informal appointment.",
        imgSrc: interview,
        link: "contact.html",
    },
    {
        id: '04',
        title: "Visa Application / Visa Acquisition",
        description: "I will apply for a visa after the offer. The affiliated administrative scrivener will contact you, so you need to prepare the application documents. We will contact the company as soon as we get a visa.",
        imgSrc: joboffer,
        link: "contact.html",
    },
    {
        id: '05',
        title: "Support Stats for Specific Skills",
        description: "We will support you as a registration support service even before you join the company. We provide support such as advance guidance and transportation.",
        imgSrc: visa,
        link: "contact.html",
    },
    {
        id: '06',
        title: "Joined the Company",
        description: "We will support you to join the company on a day that is convenient for you.",
        imgSrc: gtvisa,
        link: "contact.html",
    },
    {
        id: '07',
        title: "Support After Joining the Company",
        description: "We will provide regular interviews and other necessary support at any time (continuous support).",
        imgSrc: company,
        link: "contact.html",
    },
];

// FlowOfHiring component
function FlowOfHiring() {
    return (
        <main id="main">
            <div className="container-xxl py-5 category fadeInUp">
                <div className="container">
                    <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                        <h1 className="mb-5">Flow of Hiring Specific Skills Worker</h1>
                    </div>
                    <div className="col-lg-8 col-12 mt-3 mx-auto">
                        {hiringSteps.map((step) => (
                            <div key={step.id} className="custom-block custom-block-topics-listing bg-white shadow-lg mb-5">
                                <div className="d-flex">
                                    <img src={step.imgSrc} className="custom-block-image img-fluid" alt={step.title} />
                                    <div className="custom-block-topics-listing-info d-flex">
                                        <div>
                                            <h5 className="mb-2">{step.title}</h5>
                                            <p className="mb-0">{step.description}</p>
                                            <a href={step.link} className="btn custom-btn mt-3 mt-lg-4">Learn More</a>
                                        </div>
                                        <span className="badge bg-advertising rounded-pill ms-auto">{step.id}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </main>
    );
}

export default FlowOfHiring;
