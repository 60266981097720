import Error404Page from './pages/error404Page';
import BlogMain from './components/BlogPageComponents/BlogMain';
import BlogPage from './pages/BlogPage';
import ContactForm from './components/ContactComponents/ContactForm';
import ContactPage from './pages/ContactPage';
import BreadCrumbs from './components/BreadCrumbs';
import BlogDetailsPage from './pages/BlogDetailsPage';
import BlogDetails from './components/BlogDetailsComponent/BlogDetails';
import CompanyProfileComponents from './components/CompanyProfileComponents/CompanyProfile';
import CompanyMap from './components/CompanyProfileComponents/CompanyMap';
import CompanyProfilePage from './pages/CompanyProfilePage';
import CompanyIntro from './components/ServiceComponents/CompanyIntro';
import CompanyServicePage from './pages/CompanyServicePage';
import Random from './components/ServiceComponents/Random';
import CompanyStudent from './components/ServiceComponents/CompanyStudents';
import HireComponents from './components/CompanyRecruitmentaComponents/HireComponents';
import RecruitmentPage from './pages/RecruitmentPage';
import CarouselComponent from './components/IndexComponents/CarouselComponent';
import IndexPage from './pages/IndexPage';
import RsbPage from './pages/RsbPage';
import $ from 'jquery';
import SpecifiedSkilledWorker from './components/RecruitmentServiceBusinessComponents/SpecifiedSkilledWorkers';
import NoName from './components/RecruitmentServiceBusinessComponents/SupportedSkill';
import SupportedSkill from './components/RecruitmentServiceBusinessComponents/SupportedSkill';  
import Benefits from './components/RecruitmentServiceBusinessComponents/Benefits';
import RegisteredPerson from './components/RecruitmentServiceBusinessComponents/RegisteredPerson';
import FlowOfHiring from './components/RecruitmentServiceBusinessComponents/FlowOfHiring';
import Pricing from './components/RecruitmentServiceBusinessComponents/Pricing';
import FAQ from './components/RecruitmentServiceBusinessComponents/FAQ';
// window.$ = $;
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Testimonial from './components/IndexComponents/Testimonial';






function App() {
  return (<>
   <Router>
      {/* <nav>
        <ul>
          <li>
          00
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
      </nav> */}

      <Routes>
        <Route path="/" element={<IndexPage />} />
        <Route path="/educationalservice" element={<CompanyServicePage />} />
        <Route path="/rsb" element={<RsbPage/>} />
        <Route path="/recruitmentbusiness" element={<RecruitmentPage/>} />
        <Route path="/companyprofile" element={<CompanyProfilePage/>} />
        <Route path="/blog" element={<BlogPage/>} />
        <Route path="/contact" element={<ContactPage/>} />
        <Route path = "/errorpage" element ={<Error404Page />} />

      </Routes>
    </Router>



</>
  );
}

export default App;
