import React from 'react';
import about from '../../img/about.jpg';

function AboutUsComponent() {
    const aboutData = {
        imageSrc: about,
        sectionTitle: 'About Us',
        heading: 'Our Mission as Wiz Global',
        paragraphs: [
            'Foreign human resources who want to work in a Japanese company, we Support your dreams and goals.',
            'In the coming era, it is certain that Japan will suffer from the problem of human resources shortage. To solve this problem, it is important for foreigners to work in Japan.',
            'We operate a foreign employment activity support school so that foreigners can work for a long time at a Japanese company, and by introducing the human resources, we will solve the problem of human resources shortage in Japan and support foreign human resources.'
        ],
        buttonText: 'Read More',
        buttonLink: ''
    };

    return (
        <div className="container-xxl py-5">
            <div className="container">
                <div className="row g-5">
                    <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s" style={{minHeight: "400px"}}>
                        <div className="position-relative h-100">
                            <img className="img-fluid position-absolute w-100 h-100" src={aboutData.imageSrc} alt="" style={{objectFit: "cover"}}/>
                        </div>
                    </div>
                    <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
                        <h6 className="section-title bg-white text-start text-primary pe-3">{aboutData.sectionTitle}</h6>
                        <h1 className="mb-4">{aboutData.heading}</h1>
                        {aboutData.paragraphs.map((para, index) => (
                            <p key={index} className="mb-4">{para}</p>
                        ))}
                        <a className="btn btn-primary py-3 px-5 mt-2" href={aboutData.buttonLink}>{aboutData.buttonText}</a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutUsComponent;
