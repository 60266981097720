import React from 'react';
import wizacademy from '../../img/Wiz-Academy.png';

// Define the data object
const companyIntro = {
    imgSrc: wizacademy,
    imgAlt: 'Wiz Academy',
    title: 'What is Wiz Academy?',
    description: 'Wiz Academy is an employment activity support school for foreigners run by Wiz Global Co., Ltd. with the goal of solving the problem of human resources shortage in Japan. We offer classes in Japanese business manners, customer service, Japanese, English, etc. with our own curriculum. So that, students can work for a long time at a Japanese company. In addition, we also offer courses for specific skill test preparation and TOEIC preparation according to the wishes of students.',
    contactLink: '/elearning-1.0.0/contact.html',
    contactText: 'Contact us',
};

function CompanyIntro() {
    return (
        <section id="features" className="features section-bg">
            <div className="welcome-area section-padding3">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 align-self-center">
                            <div className="welcome-img">
                                <img src={companyIntro.imgSrc} alt={companyIntro.imgAlt} />
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="welcome-text mt-5 mt-lg-0">
                                <h2>{companyIntro.title}</h2>
                                <p className="pt-3">{companyIntro.description}</p>
                                <a href={companyIntro.contactLink} className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft">{companyIntro.contactText}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CompanyIntro;
