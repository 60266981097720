import React from 'react';
import '../../css/Services.css';
import student1 from '../../img/team-1.jpg';

// Define the data object
const studentImages = [
    { src: student1, alt: 'Student 1' },
    { src: student1, alt: 'Student 2' },
    { src: student1, alt: 'Student 3' },
    { src: student1, alt: 'Student 4' }
];

function CompanyStudent() {
    return (
        <>
            <div className="container-xxl py-5 category">
                <div className="container">
                    <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                        <h1 className="mb-5">Wiz Academy's Students</h1>
                        <h6 className="section-title bg-white text-center text-primary px-3"></h6>
                    </div>
                </div>
            </div>
    
            <div className="section">
                <div className="row">
                    <div className="row1">
                        <img 
                            src={studentImages[0].src} 
                            alt={studentImages[0].alt} 
                            style={{ height: "100%", width: "100%", borderRadius: "5px" }} 
                        />
                    </div>
                    <div className="row2">
                        <div className="column">
                            {studentImages.slice(1).map((image, index) => (
                                <div className={`column${index + 1}`} key={index}>
                                    <img 
                                        src={image.src} 
                                        alt={image.alt} 
                                        style={{ height: "80%", width: "80%", borderRadius: "5px" }} 
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CompanyStudent;
