import React from 'react';
import '../../css/Item.css';
import '../../css/bootstrap.min.css';
import '../../css/style.css';
import '../../css/main.css';
import "../../lib/animate/animate.min.css";
import "../../lib/owlcarousel/assets/owl.carousel.min.css";

function HireComponents({ title, description, items, youtubeLink }) {
    return (
        <section className="timeline-section section-padding" id="section_3">
            <div className="section-overlay"></div>

            <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <h2 className="text-white mb-4">{title}</h2>
                        <p className="text-white">{description}</p>
                    </div>

                    <div className="col-lg-10 col-12 mx-auto">
                        <div className="timeline-container">
                            <ul className="vertical-scrollable-timeline" id="vertical-scrollable-timeline">
                                <div className="list-progress">
                                    <div className="inner"></div>
                                </div>

                                {items.map((item, index) => (
                                    <li key={index}>
                                        <h4 className="text-white mb-3">{item.title}</h4>
                                        <p className="text-white">{item.description}</p>
                                        <div className="icon-holder">
                                            <i className={item.icon}></i>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>

                    <div className="col-12 text-center mt-5">
                        <p className="text-white">
                            Want to learn more?
                            <a href={youtubeLink} className="btn custom-btn custom-border-btn ms-3">Check out Youtube</a>
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default HireComponents;
