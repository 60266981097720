import React from 'react';
import PropTypes from 'prop-types';

function BreadCrumbs({ title, breadcrumbItems }) {
    return (
        <div className="container-fluid bg-primary py-5 mb-5 page-header">
            <div className="container py-5">
                <div className="row justify-content-center">
                    <div className="col-lg-10 text-center">
                        <h1 className="display-3 text-white animated slideInDown">{title}</h1>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb justify-content-center">
                                {breadcrumbItems.map((item, index) => (
                                    <li
                                        key={index}
                                        className={`breadcrumb-item ${item.isActive ? 'text-white active' : ''}`}
                                        aria-current={item.isActive ? 'page' : undefined}
                                    >
                                        {item.isActive ? (
                                            item.label
                                        ) : (
                                            <a className="text-white" href={item.href}>
                                                {item.label}
                                            </a>
                                        )}
                                    </li>
                                ))}
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    );
}

// Define default props
BreadCrumbs.defaultProps = {
    title: "Default Title",
    breadcrumbItems: [
        { label: "Home", href: "#", isActive: false },
        { label: "Page", href: "#", isActive: false }
    ]
};

// Define prop types
BreadCrumbs.propTypes = {
    title: PropTypes.string,
    breadcrumbItems: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            href: PropTypes.string,
            isActive: PropTypes.bool
        })
    )
};

export default BreadCrumbs;
