import React from 'react';
import faqGraphic from '../../img/faq_graphic.jpg';

// FAQ data array
const faqItems = [
    {
        id: '1',
        question: "What form of employment can I apply for a visa?",
        answer: "The employment form of No. 1 and No. 2 is a full-time employee (full-time work) and is directly hired by the company. No. 1 has a total period of stay of 5 years, and family members are not allowed to accompany them. After the period of stay of No. 1 is over, it can be changed to No. 2. No. 2 has no restrictions on the period of stay, and family members are allowed to accompany them. You can also change jobs. After completing issue 2, you can apply for permanent residence."
    },
    {
        id: '2',
        question: "How about social insurance tax?",
        answer: "It's basically the same as a Japanese person."
    },
    {
        id: '3',
        question: "What is the possibility of declining a student's job offer?",
        answer: "There is a possibility that students will apply for multiple companies and decline the offer. Foreigners are more likely to get a job at a company that has received a job offer early, so getting results early is an important point in securing human resources."
    },
    {
        id: '4',
        question: "Our employees can only speak Japanese, are you strong?",
        answer: "In order to obtain the status of residence of Specified Skill No. 1, it is necessary to pass N4 or higher of the Japanese Language Proficiency Test. The N4 level is a level where you can have a daily conversation, so even if the recipient can only speak the Japanese language, you will be able to communicate well."
    },
    {
        id: '5',
        question: "If you ask now, when will you start working?",
        answer: "I think it will be cheaper for about 3 months to 6 months after receiving your request. It will take some time before you can actually start working because you will need to take various steps such as recruitment, contact, visa application, etc. at the site. A medium- to long-term plan is needed."
    }
];

// FAQ component
function FAQ() {
    return (
        <main id='main'>
            <section className="faq-section section-padding" id="section_4">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-12">
                            <h2 className="mb-4">Frequently Asked Questions</h2>
                        </div>
                        <div className="clearfix"></div>
                        <div className="col-lg-5 col-12">
                            <img src={faqGraphic} className="img-fluid" alt="FAQs" />
                        </div>
                        <div className="col-lg-6 col-12 m-auto">
                            <div className="accordion" id="accordionExample">
                                {faqItems.map((item, index) => (
                                    <div key={item.id} className="accordion-item">
                                        <h2 className="accordion-header" id={`heading${item.id}`}>
                                            <button
                                                className={`accordion-button ${index === 0 ? '' : 'collapsed'}`}
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target={`#collapse${item.id}`}
                                                aria-expanded={index === 0 ? "true" : "false"}
                                                aria-controls={`collapse${item.id}`}
                                            >
                                                {item.question}
                                            </button>
                                        </h2>
                                        <div
                                            id={`collapse${item.id}`}
                                            className={`accordion-collapse collapse ${index === 0 ? 'show' : ''}`}
                                            aria-labelledby={`heading${item.id}`}
                                            data-bs-parent="#accordionExample"
                                        >
                                            <div className="accordion-body">
                                                {item.answer}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default FAQ;
