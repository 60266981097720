import React from 'react';
import PropTypes from 'prop-types';

function BlogPost({ image, date, title, author, category, excerpt, link }) {
    return (
        <div className="col-xl-4 col-md-6">
            <div className="post-item position-relative h-100">
                <div className="post-img position-relative overflow-hidden">
                    <img src={image} className="img-fluid" alt="" />
                    <span className="post-date">{date}</span>
                </div>
                <div className="post-content d-flex flex-column">
                    <h3 className="post-title">{title}</h3>
                    <div className="meta d-flex align-items-center">
                        <div className="d-flex align-items-center">
                            <i className="bi bi-person"></i> <span className="ps-2">{author}</span>
                        </div>
                        <span className="px-3 text-black-50">/</span>
                        <div className="d-flex align-items-center">
                            <i className="bi bi-folder2"></i> <span className="ps-2">{category}</span>
                        </div>
                    </div>
                    <p>{excerpt}</p>
                    <hr />
                    <a href={link} className="readmore stretched-link">
                        <span>Read More</span>
                        <i className="bi bi-arrow-right"></i>
                    </a>
                </div>
            </div>
        </div>
    );
}

BlogPost.propTypes = {
    image: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    author: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    excerpt: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired
};

export default BlogPost;
