import React from 'react';

const services = [
    {
        id: 1,
        icon: 'fa-user-tie',
        title: 'Advance Guidance',
        description: 'Explain the contents of the employment contract and salary system in a language that can be understood by the person.',
        delay: '0.1s',
    },
    {
        id: 2,
        icon: 'fa-plane',
        title: 'Pick-up and Drop-off',
        description: 'Transfer to the airport at the time of departure.',
        delay: '0.3s',
    },
    {
        id: 3,
        icon: 'fa-building',
        title: 'Livelihood Essential Contract Support',
        description: 'Support for housing, mobile phones, opening of banks, etc.',
        delay: '0.6s',
    },
    {
        id: 4,
        icon: 'fa-book-reader',
        title: 'Public Follow-up',
        description: 'Continued accompaniment and support for social security, taxes, etc. as needed.',
        delay: '0.1s',
    },
    {
        id: 5,
        icon: 'fa-balance-scale',
        title: 'Live Training',
        description: '8-hour orientation such as garbage disposal rules and disaster response.',
        delay: '0.3s',
    },
    {
        id: 6,
        icon: 'fa-phone',
        title: 'Responding to Complaints and Consultations',
        description: 'Consultations in a language that foreigners can understand.',
        delay: '0.6s',
    },
    {
        id: 7,
        icon: 'fa-people-carry',
        title: 'Providing a Japanese Language Learning Machine',
        description: 'Providing information such as information on Japanese language classes and teaching materials for learning Japanese language.',
        delay: '0.1s',
    },
    {
        id: 8,
        icon: 'fa-lightbulb',
        title: 'Regular Talks',
        description: 'The person in charge of support holds at least one interview with a foreign person and his / her boss every three months.',
        delay: '0.3s',
    },
    {
        id: 9,
        icon: 'fa-user',
        title: 'Career Change Support',
        description: 'Support for changing jobs when dismissed due to the circumstances of the receiving company.',
        delay: '0.6s',
    },
];

function SupportedSkill() {
    return (
        <section id="supported-skills">
            <div class="container">
                <div class="mx-auto text-center wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: "600px" }}>
                    <h2 class="mb-5">Supported Skills of Wiz Global <br />Residence Status</h2>
                </div>
                <div class="row g-4">
                    {services.map((service) => (
                        <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay={service.delay} key={service.id}>
                            <div class="service-item rounded h-100">
                                <div class="d-flex justify-content-between">
                                    <div class="service-icon">
                                        <i class={`fa ${service.icon} fa-2x`}></i>
                                    </div>
                                    <a class="service-btn" href="">
                                        <i class="fa fa-link fa-2x"></i>
                                    </a>
                                </div>
                                <div class="p-5">
                                    <h5 class="mb-3">{service.title}</h5>
                                    <span>{service.description}</span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default SupportedSkill;
