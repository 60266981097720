// CompanyGreetings.jsx
import React from 'react';

function CompanyGreetings({ 
    representativeTitle, 
    heading, 
    introduction, 
    companyDescription, 
    issues, 
    conclusion, 
    signature, 
    contactLink, 
    lineLink 
}) {
    return (
        <div className="container-xxl py-5">
            <div className="container">
                <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                    <h6 className="section-title bg-white text-center text-primary px-3">{representativeTitle}</h6>
                    <h1 className="mb-5">{heading}</h1>
                </div>
                <div className="row g-4">
                    <p className="text-center">
                        <a>{introduction}</a>
                        <br />
                        {companyDescription}
                        <br />
                        <br />
                        {issues.map((issue, index) => (
                            <React.Fragment key={index}>
                                ・ {issue}
                                <br />
                            </React.Fragment>
                        ))}
                        <br />
                        {conclusion}
                        <br />
                        <br />
                        <b>{signature}</b>
                    </p>
                    <p className="text-center">
                        <a className="btn btn-primary py-3 px-5" href={contactLink}>Contact us</a>
                    </p>
                    <p className="text-center">
                        <a className="btn btn-primary py-3 px-5" href={lineLink}>LINE</a>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default CompanyGreetings;
