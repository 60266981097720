import wiz from '../../img/Wiz-Academy.png';
function WizJob(){
    return(
        <section class="welcome-area section-padding3">
        <div class="container">
            <div class="row">
                <div class="col-lg-5 align-self-center">
                    <div class="welcome-img">
                        <img src = {wiz} alt="anomyisdon"/>
                    </div>
                </div>
                <div class="col-lg-7">
                    <div class="welcome-text mt-5 mt-lg-0">
                        <h2>What is Wiz Job?</h2>
                        <p class="pt-3">Wiz Job is an introduction of foreign human resources operated by Wiz Global Co., Ltd. with the goal of solving the problem of human resources shortage in Japan.</p>
                        
                        <a href="/elearning-1.0.0/contact.html" class="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft">Contact us</a>
                    </div> 
                </div>
            </div>
        </div>
    </section>

    )


}

export default WizJob;