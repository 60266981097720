import React from 'react';
import img1 from '../../img/1086a29_d172fa8c822643c093f11a2d7edc9132_mv2.webp';
import img2 from '../../img/3086a29_29faf6c969dd4a778a4e2371767fd2cc_mv2.webp';
import img3 from '../../img/3086a29_29faf6c969dd4a778a4e2371767fd2cc_mv2.webp';
import img4 from '../../img/4 peoples.webp';

const benefits = [
    {
        id: '1',
        category: 'first',
        image: img1,
        title: 'Prevent mismatches',
        description: 'We understand the image of the person you are looking for and provide education by the time you contact us.',
        link: 'img/2086a29_710425ca4d274f40b42eed885b280ebb_mv2.webp'
    },
    {
        id: '2',
        category: 'second',
        image: img2,
        title: 'Prevents troubles after entering the company',
        description: 'At Wiz Academy, we introduce trainees to companies, ensuring they are dedicated and willing to do their best. Their Japanese proficiency is equivalent to the business level (N2).',
        link: 'img/3086a29_29faf6c969dd4a778a4e2371767fd2cc_mv2.webp'
    },
    {
        id: '3',
        category: 'third',
        image: img3,
        title: 'Visa application procedure supports',
        description: 'We introduce administrative documents for foreign work visas. The administration book handles the continuation, so companies only need to prepare the necessary documents.',
        link: 'img/3086a29_29faf6c969dd4a778a4e2371767fd2cc_mv2.webp'
    },
    {
        id: '4',
        category: 'first',
        image: img4,
        title: 'Referral fee is cheaper than other companies',
        description: 'Our post-company fee and monthly support consignment fee are cheaper than other companies, which typically charge ¥30,000 to ¥50,000 monthly until retirement.',
        link: 'img/4 peoples.webp'
    }
];

function Benefits() {
    return (
        <div className="container-xxl py-5">
            <div className="container">
                <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: "600px"}}>
                    <h1 className="mb-4">Benefits of using Wiz Global</h1>
                </div>
                <div className="row mt-n2 wow fadeInUp" data-wow-delay="0.3s">
                    <div className="col-12 text-center">
                        <ul className="list-inline mb-5" id="portfolio-flters">
                            <li className="mx-2 active" data-filter="*">All</li>
                            <li className="mx-2" data-filter=".first">Category 1</li>
                            <li className="mx-2" data-filter=".second">Category 2</li>
                            <li className="mx-2" data-filter=".third">Category 3</li>
                        </ul>
                    </div>
                </div>
                <div className="row g-4 portfolio-container wow fadeInUp" data-wow-delay="0.5s">
                    {benefits.map((benefit) => (
                        <div key={benefit.id} className={`col-lg-4 col-md-6 portfolio-item ${benefit.category}`}>
                            <div className="portfolio-img rounded overflow-hidden">
                                <img className="img-fluid" src={benefit.image} alt="" />
                                <div className="portfolio-btn">
                                    <a className="btn btn-lg-square btn-outline-light rounded-circle mx-1" href={benefit.link} data-lightbox="portfolio"><i className="fa fa-eye"></i></a>
                                    <a className="btn btn-lg-square btn-outline-light rounded-circle mx-1" href="#"><i className="fa fa-link"></i></a>
                                </div>
                            </div>
                            <div className="pt-3">
                                <p className="text-primary mb-0">{benefit.title}</p>
                                <hr className="text-primary w-25 my-2" />
                                {benefit.description}
                            </div>
                        </div>
                    ))}
                </div>
                <br/>
                <br/>

                <div className="col-lg-7 mt-5 mt-lg-0">
                    <div className="welcome-text mt-5 mt-lg-0">
                        <h2>Free Support Until Hiring</h2>
                        <p className="pt-3">Wiz Job is an introduction of foreign human resources operated by Wiz Global Co., Ltd. with the goal of solving the problem of human resources shortage in Japan.</p>
                        <a href="/elearning-1.0.0/contact.html" className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft">Contact us</a>
                    </div> 
                </div>
            </div>
            <br/>
        </div>
    );
}

export default Benefits;
