import React from 'react';

// Array of offers data
const offers = [
    {
        id: 'tab-1',
        title: 'Modisit',
        heading: 'Employment Activity Support School for Foreigner Wiz Academy',
        description: 'Wiz Academy is an employment activity support school for foreigner run by Wiz Global Co., Ltd. with the goal of solving the problem of human resources shortage in Japan. We offer classes in Japanese business manners, customer service, Japanese, English, etc. with our own curriculum. So that, students can work for a long time at a Japanese company. In addition, we also offer courses for specific skill test preparation and TOEIC preparation according to the wishes of students.',
        image: 'img/features-1.jpg'
    },
    {
        id: 'tab-2',
        title: 'Praesenti',
        heading: 'Neque exercitationem debitis',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        points: [
            'Ullamco laboris nisi ut aliquip ex ea commodo consequat.',
            'Duis aute irure dolor in reprehenderit in voluptate velit.',
            'Provident mollitia neque rerum asperiores dolores quos qui a. Ipsum neque dolor voluptate nisi sed.',
            'Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate trideta storacalaperda mastiro dolore eu fugiat nulla pariatur.'
        ],
        image: 'img/features-2.jpg'
    },
    {
        id: 'tab-3',
        title: 'Explica',
        heading: 'Voluptatibus commodi accusamu',
        points: [
            'Ullamco laboris nisi ut aliquip ex ea commodo consequat.',
            'Duis aute irure dolor in reprehenderit in voluptate velit.',
            'Provident mollitia neque rerum asperiores dolores quos qui a. Ipsum neque dolor voluptate nisi sed.'
        ],
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        image: 'img/features-3.jpg'
    },
    {
        id: 'tab-4',
        title: 'Nostrum',
        heading: 'Omnis fugiat ea explicabo sunt',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        points: [
            'Ullamco laboris nisi ut aliquip ex ea commodo consequat.',
            'Duis aute irure dolor in reprehenderit in voluptate velit.',
            'Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate trideta storacalaperda mastiro dolore eu fugiat nulla pariatur.'
        ],
        image: 'img/features-4.jpg'
    }
];

function WhatWeOffer() {
    return (
        <div className="container-xxl py-5 category">
            <div className="container">
                <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                    <h6 className="section-title bg-white text-center text-primary px-3">Offers</h6>
                    <h1 className="mb-5">What We Offer</h1>
                </div>

                <section id="features" className="features section-bg">
                    <div className="container" data-aos="fade-up">
                        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                            <ul className="nav nav-tabs row g-2 d-flex">
                                {offers.map((offer) => (
                                    <li key={offer.id} className="nav-item col-3">
                                        <a className={`nav-link ${offer.id === 'tab-1' ? 'active show' : ''}`} data-bs-toggle="tab" data-bs-target={`#${offer.id}`}>
                                            <h4>{offer.title}</h4>
                                        </a>
                                    </li>
                                ))}
                            </ul>

                            <div className="tab-content">
                                {offers.map((offer) => (
                                    <div key={offer.id} className={`tab-pane ${offer.id === 'tab-1' ? 'active show' : ''}`} id={offer.id}>
                                        <div className="row">
                                            <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="100">
                                                <h3>{offer.heading}</h3>
                                                <p className="fst-italic">{offer.description}</p>
                                                {offer.points && (
                                                    <ul>
                                                        {offer.points.map((point, index) => (
                                                            <li key={index}><i className="bi bi-check2-all"></i> {point}</li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </div>
                                            <div className="col-lg-6 order-1 order-lg-2 text-center" data-aos="fade-up" data-aos-delay="200">
                                                <img src={offer.image} alt="" className="img-fluid" />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default WhatWeOffer;
