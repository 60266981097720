import img1 from '../../img/1086a29_d172fa8c822643c093f11a2d7edc9132_mv2.webp';
import img2 from '../../img/2086a29_710425ca4d274f40b42eed885b280ebb_mv2.webp';

function Random() {
    const data = [
        {
            title: "Foreigner employment activity support course",
            description: "Our unique curriculum, Wiz Academy, develops foreign human resources who can work for a long time in Japan. In order to work for a long time at a Japanese company, you will learn basic Japanese business etiquette, customer service Japanese, English, and differences from your own country, as well as classes and training such as creating resumes and resumes for job hunting and interview preparation.",
            image: img1,
            buttonText: "Get started",
            linkText: "Learn more",
            buttonLink: "#!",
            moreLink: "#"
        },
        {
            title: "Course for specified skill Workers(SSW) test",
            description: "We are conducting exam-oriented classes of nursing care, restaurant service, hotel service, food and beverage manufacturing, fishing and more.",
            image: img2,
            buttonText: "Get started",
            linkText: "Learn more",
            buttonLink: "#!",
            moreLink: "#"
        }
    ];

    return (
        <>
            {data.map((item, index) => (
                <div key={index} className={`text-center wow fadeInUp ${index % 2 === 0 ? '' : 'mt-5'}`} data-wow-delay="0.1s">
                    <div className="container">
                        <div className="row flex-center">
                            <div className={`col-md-6 ${index % 2 === 0 ? 'text-center text-md-start' : 'text-center text-md-start order-md-2'}`}>
                                <b className="fw-normal fs-3" data-zanim-xs='{"delay":0.3}' data-zanim-trigger="scroll">{item.title}</b>
                                <hr />
                                <p className="fs-0 pe-xl-8" data-zanim-xs='{"delay":0.5}' data-zanim-trigger="scroll">{item.description}</p>
                                <div className="d-flex justify-content-space-between align-item-center my-3 mt-2">
                                    {/* Add any additional content here if needed */}
                                </div>
                                <button className="btn btn-sm btn-primary my-4 me-1" href={item.buttonLink} role="button">{item.buttonText}</button>
                                <a className="btn btn-sm my-2 btn-default" href={item.moreLink} role="button">{item.linkText}<i className="fas fa-arrow-right ms-2"></i></a>
                            </div>
                            <div className="col-md-6 mb-4">
                                <img className="w-100" src={item.image} alt="..." />
                            </div>
                        </div>
                    </div>
                    {index % 2 === 1 && <section></section>} {/* Add section element conditionally if needed */}
                </div>
            ))}
        </>
    );
}

export default Random;
