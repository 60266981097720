import ContactForm from "../components/ContactComponents/ContactForm";
import ContactHeader from "../components/ContactComponents/ContactHeader";
import Header from "../components/Header";
import NavBar from "../components/NavBar";
import TopNavBar from "../components/TopNavBar";

function ContactPage(){
    return(<>
        <TopNavBar />
        <NavBar />
  <ContactHeader />
        <ContactForm />
        </>

    )

}
export default ContactPage;