import React from 'react';
import '../../animations/Animation.css';

// Data for each registered person category
const registeredPersons = [
    {
        id: 1,
        title: "History of stay in Japan",
        description: "3 years or more (after graduating from Japanese language school, go to a specialized school, etc.)",
        delay: "0.1s"
    },
    {
        id: 2,
        title: "Overseas personnel",
        description: "Persons who have returned from Japan or have qualifications for specific skills in the country.",
        delay: "0.3s"
    },
    {
        id: 3,
        title: "Education",
        description: "Higher or higher graduate or higher graduate (including academic background in the country)",
        delay: "0.6s"
    },
    {
        id: 4,
        title: "Age",
        description: "20s-30s",
        delay: "0.1s"
    },
    {
        id: 5,
        title: "Country of Citizenship",
        description: "Nepal, Bangladesh, Vietnam, Myanmar, Sri Lanka, Pakistan, Indonesia, China, etc.",
        delay: "0.3s"
    },
    {
        id: 6,
        title: "Language",
        description: "Japanese / Japanese / English",
        delay: "0.6s"
    },
    {
        id: 7,
        title: "Japanese language ability",
        description: "Japanese-Language Proficiency Test (JLPT) Passed N4 to N2.",
        delay: "0.1s"
    },
    {
        id: 8,
        title: "Successful proficiency test",
        description: "Skill test by acceptance field.",
        delay: "0.3s"
    },
    {
        id: 9,
        title: "Work experience",
        description: "Permanent or part-time job experience overseas, part-time job or full-time employee experience in Japan.",
        delay: "0.3s"
    }
];

function RegisteredPerson() {
    return (
        <section id="features" className="features section-bg">
            <div className="container">
                <div className="mx-auto text-center wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: "600px" }}>
                    <h2 className="mb-5">Persons Registered in <br />Wiz Global</h2>
                </div>
                <div className="row g-4">
                    {registeredPersons.map(person => (
                        <div 
                            key={person.id} 
                            className="col-lg-4 col-md-6 wow fadeInUp" 
                            data-wow-delay={person.delay}
                        >
                            <div className="service-item rounded h-100">
                                <div className="d-flex justify-content-between">
                                    <div className="service-icon">
                                        {/* Optional: Add an icon here if needed */}
                                    </div>
                                    <a className="service-btn" href="">
                                        {/* Optional: Add button content if needed */}
                                    </a>
                                </div>
                                <div className="p-5">
                                    <h5 className="mb-3">{person.title}</h5>
                                    <span>{person.description}</span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default RegisteredPerson;
