import React from 'react';

function ServiceOffering() {
    const services = [
        {
            id: 1,
            icon: 'fa-plane-departure',
            title: 'Foreign goods referral',
            description: 'Japan Job School graduates connect foreign job seekers to Japanese companies.',
            aosDelay: '0.1s'
        },
        {
            id: 2,
            icon: 'fa-globe',
            title: 'Registration support',
            description: 'Supporting NPO activities for foreign workers.',
            aosDelay: '0.3s'
        },
        {
            id: 3,
            icon: 'fa-hands-helping',
            title: 'SES service',
            description: 'Resource allocation via our engineer network.',
            aosDelay: '0.5s'
        },
        {
            id: 4,
            icon: 'fa-book-open',
            title: 'Special Course',
            description: 'Custom courses: TOEIC, IELTS, and more.',
            aosDelay: '0.7s'
        }
    ];

    return (
        <div className="container-xxl py-5">
            <div className="container">
                <div className="row g-4">
                    {services.map(service => (
                        <div key={service.id} className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay={service.aosDelay}>
                            <div className="service-item text-center pt-3">
                                <div className="p-4">
                                    <i className={`fa fa-3x ${service.icon} text-primary mb-4`}></i>
                                    <h5 className="mb-3">{service.title}</h5>
                                    <p>{service.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default ServiceOffering;
