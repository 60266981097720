import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

// Import images
import Testimonial1 from '../../img/testimonial-1.jpg';
import Testimonial2 from '../../img/testimonial-2.jpg';
import Testimonial3 from '../../img/testimonial-3.jpg';
import Testimonial4 from '../../img/team-1.jpg';

function Testimonial() {
    // Array of testimonial data
    const testimonials = [
        {
            id: 1,
            image: Testimonial1,
            name: 'Client Name',
            profession: 'Profession',
            text: 'Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam amet diam et eos. Clita erat ipsum et lorem et sit.',
        },
        {
            id: 2,
            image: Testimonial2,
            name: 'Client Name',
            profession: 'Profession',
            text: 'Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam amet diam et eos. Clita erat ipsum et lorem et sit.',
        },
        {
            id: 3,
            image: Testimonial3,
            name: 'Client Name',
            profession: 'Profession',
            text: 'Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam amet diam et eos. Clita erat ipsum et lorem et sit.',
        },
        {
            id: 4,
            image: Testimonial4,
            name: 'Client Name',
            profession: 'Profession',
            text: 'Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam amet diam et eos. Clita erat ipsum et lorem et sit.',
        }
    ];

    return (
        <div className="container-xxl py-5 wow fadeInUp" data-wow-delay="0.1s">
            <div className="container">
                <div className="text-center">
                    <h6 className="section-title bg-white text-center text-primary px-3">Testimonial</h6>
                    <h1 className="mb-5">Our Students Say!</h1>
                </div>
                <OwlCarousel
                    className="owl-theme testimonial-carousel"
                    loop
                    margin={30}
                    nav
                    items={3}
                    autoplay={true}
                    autoplayTimeout={5000}
                    autoplayHoverPause={true}
                    responsive={{
                        0: { items: 1 },
                        576: { items: 2 },
                        768: { items: 2 },
                        992: { items: 3 }
                    }}
                >
                    {testimonials.map(testimonial => (
                        <div key={testimonial.id} className="testimonial-item text-center">
                            <img
                                className="border rounded-circle p-2 mx-auto mb-3"
                                src={testimonial.image}
                                style={{ width: "80px", height: "80px" }}
                                alt={testimonial.name}
                            />
                            <h5 className="mb-0">{testimonial.name}</h5>
                            <p>{testimonial.profession}</p>
                            <div className="testimonial-text bg-light text-center p-4">
                                <p className="mb-0">{testimonial.text}</p>
                            </div>
                        </div>
                    ))}
                </OwlCarousel>
            </div>
        </div>
    );
}

export default Testimonial;
