import React from 'react';
import course1 from '../../img/course-1.jpg';
import course2 from '../../img/course-2.jpg';
import course3 from '../../img/course-3.jpg';

function Courses() {
    const courses = [
        {
            id: 1,
            img: course1,
            price: '$149.00',
            rating: 5,
            title: 'TOIEC',
            instructor: 'John Doe',
            duration: '1.49 Hrs',
            students: '30 Students',
            link: '#'
        },
        {
            id: 2,
            img: course2,
            price: '$149.00',
            rating: 5,
            title: 'IELTS',
            instructor: 'John Doe',
            duration: '1.49 Hrs',
            students: '30 Students',
            link: '#'
        },
        {
            id: 3,
            img: course3,
            price: '$149.00',
            rating: 5,
            title: 'ADVANCE JAPANESE LANGUAGE',
            instructor: 'John Doe',
            duration: '1.49 Hrs',
            students: '30 Students',
            link: '#'
        }
    ];

    return (
        <div className="container-xxl py-5">
            <div className="container">
                <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                    <h6 className="section-title bg-white text-center text-primary px-3">Courses</h6>
                    <h1 className="mb-5">Popular Courses</h1>
                </div>
                <div className="row g-4 justify-content-center">
                    {courses.map((course, index) => (
                        <div key={course.id} className={`col-lg-4 col-md-6 wow fadeInUp`} data-wow-delay={`${0.1 + 0.2 * index}s`}>
                            <div className="course-item bg-light">
                                <div className="position-relative overflow-hidden">
                                    <img className="img-fluid" src={course.img} alt={course.title} />
                                    <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                                        <a href={course.link} className="flex-shrink-0 btn btn-sm btn-primary px-3 border-end" style={{ borderRadius: '30px 0 0 30px' }}>Read More</a>
                                        <a href={course.link} className="flex-shrink-0 btn btn-sm btn-primary px-3" style={{ borderRadius: '0 30px 30px 0' }}>Join Now</a>
                                    </div>
                                </div>
                                <div className="text-center p-4 pb-0">
                                    <h3 className="mb-0">{course.price}</h3>
                                    <div className="mb-3">
                                        {[...Array(course.rating)].map((_, i) => (
                                            <small key={i} className="fa fa-star text-primary"></small>
                                        ))}
                                        <small>(123)</small>
                                    </div>
                                    <h5 className="mb-4">{course.title}</h5>
                                </div>
                                <div className="d-flex border-top">
                                    <small className="flex-fill text-center border-end py-2">
                                        <i className="fa fa-user-tie text-primary me-2"></i>{course.instructor}
                                    </small>
                                    <small className="flex-fill text-center border-end py-2">
                                        <i className="fa fa-clock text-primary me-2"></i>{course.duration}
                                    </small>
                                    <small className="flex-fill text-center py-2">
                                        <i className="fa fa-user text-primary me-2"></i>{course.students}
                                    </small>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Courses;
