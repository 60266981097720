import BlogMain from "../components/BlogPageComponents/BlogMain";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import TopNavBar from "../components/TopNavBar";
import '../css/bootstrap.min.css';
import '../css/Blog.css';
import BreadCrumbs from "../components/BreadCrumbs";
{/* <link href="css/bootstrap.min.css" rel="stylesheet"> */}
function BlogPage(){
    
    const title = "Blog Page";
    const breadcrumbItems = [
        { label: "Home", href: "#", isActive: false },
        { label: "Educational Services", href: "#", isActive: true }]
    return(
        <>
        <TopNavBar />
        <NavBar />
        <BreadCrumbs title={title} breadcrumbItems={breadcrumbItems} />
        <BlogMain />
        <Footer />
        </>

    )

}
export default BlogPage;