import React from 'react';
import '../css/style.css';
import '../lib/animate/animate.min.css';
import course1 from '../img/course-1.jpg';
import course2 from '../img/course-2.jpg';
import course3 from '../img/course-3.jpg';

function Footer() {
    const quickLinks = [
        { text: 'About Us', href: '#' },
        { text: 'Contact Us', href: '#' },
        { text: 'Privacy Policy', href: '#' },
        { text: 'Terms & Condition', href: '#' },
        { text: 'FAQs & Help', href: '#' }
    ];

    const contactInfo = [
        { icon: 'fa-map-marker-alt', text: '123 Street, New York, USA' },
        { icon: 'fa-phone-alt', text: '+012 345 67890' },
        { icon: 'fa-envelope', text: 'info@example.com' }
    ];

    const socialMediaLinks = [
        { icon: 'fab fa-twitter', url: '#' },
        { icon: 'fab fa-facebook-f', url: '#' },
        { icon: 'fab fa-youtube', url: '#' },
        { icon: 'fab fa-linkedin-in', url: '#' }
    ];

    const galleryImages = [
        course1, course2, course3, course2, course1, course2
    ];

    return (
        <div className="container-fluid bg-dark text-light footer pt-5 mt-5 wow fadeIn" data-wow-delay="0.1s">
            <div className="container py-5">
                <div className="row g-5">
                    <div className="col-lg-3 col-md-6">
                        <h4 className="text-white mb-3">Quick Link</h4>
                        {quickLinks.map((link, index) => (
                            <a key={index} className="btn btn-link" href={link.href}>{link.text}</a>
                        ))}
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <h4 className="text-white mb-3">Contact</h4>
                        {contactInfo.map((info, index) => (
                            <p key={index} className="mb-2">
                                <i className={`fa ${info.icon} me-3`}></i>{info.text}
                            </p>
                        ))}
                        <div className="d-flex pt-2">
                            {socialMediaLinks.map((link, index) => (
                                <a key={index} className="btn btn-outline-light btn-social" href={link.url}>
                                    <i className={link.icon}></i>
                                </a>
                            ))}
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <h4 className="text-white mb-3">Gallery</h4>
                        <div className="row g-2 pt-2">
                            {galleryImages.map((image, index) => (
                                <div key={index} className="col-4">
                                    <img className="img-fluid bg-light p-1" src={image} alt="" />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <h4 className="text-white mb-3">Newsletter</h4>
                        <p>Dolor amet sit justo amet elitr clita ipsum elitr est.</p>
                        <div className="position-relative mx-auto" style={{ maxWidth: "400px" }}>
                            <input className="form-control border-0 w-100 py-3 ps-4 pe-5" type="text" placeholder="Your email" />
                            <button type="button" className="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2">SignUp</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="copyright">
                    <div className="row">
                        <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                            &copy; <a className="border-bottom" href="#">Your Site Name</a>, All Right Reserved.
                            Designed By <a className="border-bottom" href="https://htmlcodex.com">HTML Codex</a><br />
                            Distributed By <a className="border-bottom" href="https://themewagon.com">ThemeWagon</a>
                        </div>
                        <div className="col-md-6 text-center text-md-end">
                            <div className="footer-menu">
                                <a href="#">Home</a>
                                <a href="#">Cookies</a>
                                <a href="#">Help</a>
                                <a href="#">FQAs</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
