import React from 'react';
import img1 from '../../img/undraw_portfolio_feedback_6r17.png';

// Define the job details array
const jobDetails = [
    {
        id: '1',
        image: img1,
        title: 'Wiz Job',
        subtitle: "What's Specified Skilled Workers (SSW)?",
        description: `As Japan's serious labor shortage accelerates, the status of residence "Specified Skilled Worker" is attracting attention as a labor force. In the past, technical and specialized foreign workers called white-collar workers were accepted, but with the aim of resolving the labor shortage in Japan, acceptance has begun in 12 fields where labor shortages are particularly pronounced.`,
        additionalInfo: `[12 fields (former 14 fields) that are acceptable for specific skills]`
    }
];

// Define the CompanyJob component
function CompanyJob() {
    return (
        <section className="pt-5" id="manager">
            <div className="container">
                <div className="row">
                    {jobDetails.map((job) => (
                        <React.Fragment key={job.id}>
                            <div className="col-lg-6">
                                <img className="img-fluid" src={job.image} alt={job.title} />
                            </div>
                            <div className="col-lg-6">
                                <h5 className="text-secondary">{job.title}</h5>
                                <p className="fs-7 fw-bolder mb-xxl-8">{job.subtitle}</p>
                                <p className="mb-4 fw-medium text-secondary">
                                    {job.description}
                                    <br/><br/>{job.additionalInfo}
                                </p>
                            </div>
                        </React.Fragment>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default CompanyJob;
