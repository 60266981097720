import React from 'react';
import '../../animations/Animation.css';

// Data for each pricing plan
const pricingPlans = [
    {
        id: 1,
        title: "Basic",
        description: "Eirmod erat dolor amet est clita lorem erat justo rebum elitr eos",
        price: "2.49",
        per: "/ Month",
        offer: "Save 30%",
        borderColor: "border-primary",
        btnColor: "btn-primary",
        icon: "fa-share-alt"
    },
    {
        id: 2,
        title: "Advance",
        description: "Eirmod erat dolor amet est clita lorem erat justo rebum elitr eos",
        price: "5.49",
        per: "/ Month",
        offer: "Save 30%",
        borderColor: "border-secondary",
        btnColor: "btn-secondary",
        icon: "fa-server"
    },
    {
        id: 3,
        title: "Premium",
        description: "Eirmod erat dolor amet est clita lorem erat justo rebum elitr eos",
        price: "11.49",
        per: "/ Month",
        offer: "Save 30%",
        borderColor: "border-primary",
        btnColor: "btn-primary",
        icon: "fa-cog"
    }
];

function Pricing() {
    return (
        <main id="main">
            <section id="features" className="features section-bg fadeInUp">
                <div className="container-xxl py-5 category">
                    <div className="container">
                        <div className="mx-auto text-center" style={{ maxWidth: "600px" }}>
                            <h2 className="mb-5">Persons Registered in <br />Wiz Global</h2>
                            <div className="row gy-5 gx-4">
                                {pricingPlans.map((plan, index) => (
                                    <div 
                                        key={plan.id} 
                                        className="col-lg-4 col-md-6 fadeInUp" 
                                        style={{ animationDelay: `${index * 0.2}s` }}
                                    >
                                        <div className={`position-relative shadow rounded border-top border-5 ${plan.borderColor}`}>
                                            <div 
                                                className={`d-flex align-items-center justify-content-center position-absolute top-0 start-50 translate-middle bg-${plan.btnColor.replace('btn-', '')} rounded-circle`} 
                                                style={{ width: "45px", height: "45px", marginTop: "-3px" }}
                                            >
                                                <i className={`fa ${plan.icon} text-white`}></i>
                                            </div>
                                            <div className="text-center border-bottom p-4 pt-5">
                                                <h4 className="fw-bold">{plan.title}</h4>
                                                <p className="mb-0">{plan.description}</p>
                                            </div>
                                            <div className="text-center border-bottom p-4">
                                                <p className="text-primary mb-1">Latest Offer - <strong>{plan.offer}</strong></p>
                                                <h1 className="mb-3">
                                                    <small className="align-top" style={{ fontSize: "22px", lineHeight: "45px" }}>$</small>
                                                    {plan.price}
                                                    <small className="align-bottom" style={{ fontSize: "16px", lineHeight: "40px" }}>{plan.per}</small>
                                                </h1>
                                                <a className={`btn ${plan.btnColor} px-4 py-2`} href="">Buy Now</a>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default Pricing;
