import Footer from "../components/Footer";
import AboutUsComponent from "../components/IndexComponents/AboutUsComponent";
import ActivitySupport from "../components/IndexComponents/ActivitySupport";
import CarouselComponent from "../components/IndexComponents/CarouselComponent";
import CourseCategories from "../components/IndexComponents/CourseCategories";
import Courses from "../components/IndexComponents/Courses";
import IndexCompanyTeam from "../components/IndexComponents/IndexCompanyTeam";
import CompanyTeam from "../components/IndexComponents/IndexCompanyTeam";
import RecentBlogPost from "../components/IndexComponents/RecentBlogPost";
import ServiceOffering from "../components/IndexComponents/ServiceOfferings";
import Testimonial from "../components/IndexComponents/Testimonial";
import WhatWeOffer from "../components/IndexComponents/WhatWeOffer";
import NavBar from "../components/NavBar";
import TopNavBar from "../components/TopNavBar";

import team1 from '../img/team-1.jpg';
import team2 from '../img/team-2.jpg';
import team3 from '../img/team-3.jpg';
import team4 from '../img/team-4.jpg';

function IndexPage(){

    const teamMembers = [
        {
            imageSrc: team1,
            name: "Instructor 1",
            designation: "Designation 1",
            facebook: "https://facebook.com/instructor1",
            twitter: "https://twitter.com/instructor1",
            instagram: "https://instagram.com/instructor1"
        },
        {
            imageSrc: team2,
            name: "Instructor 2",
            designation: "Designation 2",
            facebook: "https://facebook.com/instructor2",
            twitter: "https://twitter.com/instructor2",
            instagram: "https://instagram.com/instructor2"
        },
        {
            imageSrc: team3,
            name: "Instructor 3",
            designation: "Designation 3",
            facebook: "https://facebook.com/instructor3",
            twitter: "https://twitter.com/instructor3",
            instagram: "https://instagram.com/instructor3"
        },
        {
            imageSrc: team4,
            name: "Instructor 4",
            designation: "Designation 4",
            facebook: "https://facebook.com/instructor4",
            twitter: "https://twitter.com/instructor4",
            instagram: "https://instagram.com/instructor4"
        }
    ];
    return(
        <>
        <TopNavBar />
        <NavBar />
        <CarouselComponent />
        <ServiceOffering />
        <AboutUsComponent />
        <WhatWeOffer />
        <ActivitySupport />
        <CourseCategories />

        <Courses />
        <IndexCompanyTeam teamMembers={teamMembers} />
        
        <Testimonial />
        <RecentBlogPost />
        <Footer />
            </>
    )

}
export default IndexPage;