import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import img1 from '../../img/carousel-1.jpg';
import img2 from '../../img/carousel-2.jpg';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

function CarouselComponent() {
    const carouselItems = [
        {
            imageSrc: img1,
            title: 'Employment Activity Support School for Foreigner Wiz Academy',
            subtitle: 'Wiz Global',
            description: 'Wiz Academy is an employment activity support school for foreigners run by Wiz Global Co., Ltd. with the goal of solving the problem of human resources shortage in Japan.',
            button1Text: 'Read More',
            button2Text: 'Join Now',
            button1Href: '#',
            button2Href: '#'
        },
        {
            imageSrc: img2,
            title: 'Foreign Human Resources Recruitment Support Service Wiz Job',
            subtitle: 'Wiz Global',
            description: 'Wiz Job is an introduction of foreign human resources operated by Wiz Global Co., Ltd. with the goal of solving the problem of human resources shortage in Japan.',
            button1Text: 'Read More',
            button2Text: 'Join Now',
            button1Href: '#',
            button2Href: '#'
        },
        {
            imageSrc: img2,
            title: 'Registration Support Service SSW',
            subtitle: 'Wiz Global',
            description: 'As a registration support organization, Wiz Global introduces and supports human resources with specific skills to Japanese companies from Japan and overseas.',
            button1Text: 'Read More',
            button2Text: 'Join Now',
            button1Href: '#',
            button2Href: '#'
        }
    ];

    return (
        <div className="container-fluid p-0 mb-5">
            <OwlCarousel
                className="owl-theme"
                loop
                margin={10}
                nav
                items={1}
                autoplay={true}
                autoplayTimeout={5000}
                autoplayHoverPause={true}
            >
                {carouselItems.map((item, index) => (
                    <div key={index} className="item">
                        <img className="img-fluid" src={item.imageSrc} alt="" />
                        <div className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center" style={{ background: "rgba(24, 29, 56, .7)" }}>
                            <div className="container">
                                <div className="row justify-content-start">
                                    <div className="col-sm-10 col-lg-8">
                                        <h5 className="text-primary text-uppercase mb-3 animated slideInDown">{item.subtitle}</h5>
                                        <h1 className="display-3 text-white animated slideInDown">{item.title}</h1>
                                        <p className="fs-5 text-white mb-4 pb-2">{item.description}</p>
                                        <a href={item.button1Href} className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft">{item.button1Text}</a>
                                        <a href={item.button2Href} className="btn btn-light py-md-3 px-md-5 animated slideInRight">{item.button2Text}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </OwlCarousel>
        </div>
    );
}

export default CarouselComponent;
