import React from 'react';
import cat1 from '../../img/cat-1.jpg';
import cat2 from '../../img/cat-2.jpg';
import cat3 from '../../img/cat-3.jpg';
import cat4 from '../../img/cat-4.jpg';

function CourseCategories() {
    const categories = [
        {
            id: 1,
            img: cat1,
            title: 'TOEIC CLASS',
            link: ''
        },
        {
            id: 2,
            img: cat2,
            title: 'IELTS CLASS',
            link: ''
        },
        {
            id: 3,
            img: cat3,
            title: 'SPECIAL COUNSELING',
            link: ''
        },
        {
            id: 4,
            img: cat4,
            title: 'MANY MORE',
            link: ''
        }
    ];

    return (
        <div className="container-xxl py-5 category">
            <div className="container">
                <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                    <h6 className="section-title bg-white text-center text-primary px-3">Categories</h6>
                    <h1 className="mb-5">Courses Categories</h1>
                </div>
                <div className="row g-3">
                    <div className="col-lg-7 col-md-6">
                        <div className="row g-3">
                            {categories.slice(0, 3).map((category, index) => (
                                <div key={category.id} className={`col-lg-${index === 0 ? '12' : '6'} col-md-12 wow zoomIn`} data-wow-delay={`${0.1 + 0.2 * index}s`}>
                                    <a className="position-relative d-block overflow-hidden" href={category.link}>
                                        <img className="img-fluid" src={category.img} alt={category.title} />
                                        <div className="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3" style={{ margin: '1px' }}>
                                            <h5 className="m-0">{category.title}</h5>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-6 wow zoomIn" data-wow-delay="0.7s" style={{ minHeight: '350px' }}>
                        <a className="position-relative d-block h-100 overflow-hidden" href={categories[3].link}>
                            <img className="img-fluid position-absolute w-100 h-100" src={categories[3].img} alt={categories[3].title} style={{ objectFit: 'cover' }} />
                            <div className="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3" style={{ margin: '1px' }}>
                                <h5 className="m-0">{categories[3].title}</h5>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CourseCategories;
