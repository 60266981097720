import React from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../img/logo.png';
import '../css/navbar.css';

function NavBar() {
    const navItems = [
        { to: "/", label: "Home" },
        { to: "/educationalservice", label: "Educational Service" },
        { to: "/recruitmentbusiness", label: "Recruitment Service" },
        { to: "/rsb", label: "Recruitment Business Service" },
        { to: "/companyprofile", label: "Company Profile" },
        { to: "/blog", label: "Blog" },
        { to: "/contact", label: "Contact" }
    ];

    return (
        <nav className="navbar navbar-expand-lg bg-white navbar-light shadow sticky-top p-0 overflow-auto scrollable-navbar" style={{position:"sticky", top:"0", zIndex:"999"}}>
            <div className="navbar-brand d-flex align-items-center px-1 px-lg-0">
                <img src={logo} alt="Logo" />
            </div>

            <button type="button" className="navbar-toggler me-4" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarCollapse">
                <div className="navbar-nav ms-auto p-4 p-lg-0">
                    {navItems.map((item, index) => (
                        <NavLink 
                            key={index} 
                            to={item.to} 
                            className="nav-item nav-link"
                            activeClassName="active" // Optional: Add an active class when the link is active
                        >
                            {item.label}
                        </NavLink>
                    ))}
                </div>
                <NavLink 
                    to='/visitor' 
                    className="btn btn-primary py-4 px-lg-5 d-none d-lg-block"
                >
                    Join Now<i className="fa fa-arrow-right ms-3"></i>
                </NavLink>
            </div>
        </nav>
    );
}

export default NavBar;
