import React from 'react';
import people4 from '../../img/4 peoples.webp';

// Data for the Specified Skilled Worker section
const skilledWorkerInfo = {
    title: "Points of Specific Skill 1 Residence Status",
    highlights: [
        {
            id: 1,
            content: "Period of stay: 5 years in total"
        },
        {
            id: 2,
            content: " Can be changed to 'Specific Skill No. 2' after the completion of 'Specific Skill No. 1'"
        },
        {
            id: 3,
            content: " Requirements "
        },
        {
            id: 4,
            content: "- Japanese Level: Japanese-Language Proficiency Test (JLPT) N4 above, or 200 points above in the International Exchange Basic Japanese Language Proficiency Test (JFT Basic Test)"
        },
        {
            id: 5,
            content: "- Skill level: Passed the technical intern training for each field of acceptance * Exemption for those who have completed technical intern training No. 2"
        }
    ],
    buttons: [
        {
            id: 1,
            text: "Get started",
            href: "#!",
            className: "btn btn-sm btn-primary my-4 me-1"
        },
        {
            id: 2,
            text: "Learn more",
            href: "#",
            className: "btn btn-sm my-2 btn-default",
            icon: "fas fa-arrow-right ms-2"
        }
    ],
    image: {
        src: people4,
        alt: "Specified Skilled Worker Image"
    }
};

function SpecifiedSkilledWorker() {
    return (
        <section id="features" className="features section-bg">
            <div className="container-xxl py-6">
                <div className="mx-auto text-center wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: "600px" }}>
                    <h2 className="mb-5">
                        {skilledWorkerInfo.title.split(' ').slice(0, 3).join(' ')} <br />
                        <a style={{ color: "#06BBCC" }}>{skilledWorkerInfo.title.split(' ').slice(3, 6).join(' ')}</a> <br />
                        {skilledWorkerInfo.title.split(' ').slice(6).join(' ')}
                    </h2>
                </div>
                <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                    <div className="container">
                        <div className="row flex-center">
                            <div className="col-md-6 text-center text-md-start">
                                {skilledWorkerInfo.highlights.map((highlight) => (
                                    <p key={highlight.id} className="fs-0 pe-xl-8" data-zanim-xs='{"delay":0.5}' data-zanim-trigger="scroll">
                                        {highlight.content}
                                    </p>
                                ))}
                                <div className="d-flex justify-content-space-between align-items-center my-3 mt-2">
                                    {skilledWorkerInfo.buttons.map((button) => (
                                        <a key={button.id} className={button.className} href={button.href} role="button">
                                            {button.text}
                                            {button.icon && <i className={button.icon}></i>}
                                        </a>
                                    ))}
                                </div>
                            </div>
                            <div className="col-md-6 mb-4">
                                <img className="w-100" src={skilledWorkerInfo.image.src} alt={skilledWorkerInfo.image.alt} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SpecifiedSkilledWorker;
