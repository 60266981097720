import React from 'react';

function TopNavBar() {
    const contactInfo = [
        { icon: 'fa-map-marker-alt', text: '1-29-7 Takadanobaba, Shinjuku-ku, Tokyo' },
        { icon: 'far fa-clock', text: 'Mon - Fri: 9am - 6pm' },
        { icon: 'fa-phone-alt', text: '03-6709-6852' }
    ];

    const socialMediaLinks = [
        { icon: 'fab fa-facebook-f', url: '#' },
        { icon: 'fab fa-twitter', url: '#' },
        { icon: 'fab fa-linkedin-in', url: '#' },
        { icon: 'fab fa-instagram', url: '#' }
    ];

    return (
        <div className="container-fluid bg-light p-0">
            <div className="row gx-0 d-none d-lg-flex">
                <div className="col-lg-7 px-5 text-start">
                    {contactInfo.slice(0, 2).map((item, index) => (
                        <div key={index} className="h-100 d-inline-flex align-items-center py-3 me-4">
                            <small className={`fa ${item.icon} text-primary me-2`}></small>
                            <small>{item.text}</small>
                        </div>
                    ))}
                </div>
                <div className="col-lg-5 px-5 text-end">
                    {contactInfo.slice(2).map((item, index) => (
                        <div key={index} className="h-100 d-inline-flex align-items-center py-3 me-4">
                            <small className={`fa ${item.icon} text-primary me-2`}></small>
                            <small>{item.text}</small>
                        </div>
                    ))}
                    <div className="h-100 d-inline-flex align-items-center">
                        {socialMediaLinks.map((link, index) => (
                            <a key={index} className="btn btn-sm-square bg-white text-primary me-1" href={link.url}>
                                <i className={link.icon}></i>
                            </a>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TopNavBar;
