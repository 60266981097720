import React from 'react';
import PropTypes from 'prop-types';


function CompanyProfileComponents({
    imageSrc,
    companyName,
    establishedDate,
    capital,
    academyLocations,
    businessContent,
    representative,
    url,
    email,
    tel,
    fax,
}) {
    return (
        <div className="container py-5">
            <div className="row g-5 align-items-center">
                <div className="col-lg-6 col-xl-5 wow fadeInLeft" data-wow-delay="0.1s">
                    <div className="about-img">
                        <img src={imageSrc} className="img-fluid w-100 rounded-top bg-white" alt="Company Image" />
                    </div>
                </div>

                <div className="col-lg-6 col-xl-7 wow fadeInRight" data-wow-delay="0.3s">
                    <h4 className="text-primary">Company Profile</h4>
                    <br />

                    <div className="row g-4 justify-content-between mb-5">
                        <div className="col-lg-6 col-xl-5">
                            <p className="text-dark">
                                <i className="fas fa-check-circle text-primary me-1"></i>
                                Company Name: <b>{companyName}</b>
                            </p>
                            <p className="text-dark mb-0">
                                <i className="fas fa-check-circle text-primary me-1"></i>
                                Established Date: <b>{establishedDate}</b>
                            </p>
                            <br />
                            <p className="text-dark mb-0">
                                <i className="fas fa-check-circle text-primary me-1"></i>
                                Capital: <b>{capital}</b>
                            </p>
                            <br />
                            <p className="text-dark mb-0">
                                <i className="fas fa-check-circle text-primary me-1"></i>
                                Wiz Academy: <b>
                                    {academyLocations.map((location, index) => (
                                        <div key={index}>
                                            {location.city}: {location.address} <a href={location.website} target="_blank" rel="noopener noreferrer">{location.website}</a>
                                        </div>
                                    ))}
                                </b>
                            </p>
                            <br />
                            <p className="text-dark mb-0">
                                <i className="fas fa-check-circle text-primary me-1"></i>
                                Business Content: <b>
                                    <br />
                                    {businessContent.map((content, index) => (
                                        <div key={index}>
                                            ・{content}
                                            <hr />
                                        </div>
                                    ))}
                                </b>
                            </p>
                        </div>

                        <div className="col-lg-6 col-xl-7">
                            <p className="text-dark">
                                <i className="fas fa-check-circle text-primary me-1"></i>
                                Representative: <b>{representative}</b>
                            </p>
                            <br />
                            <p className="text-dark">
                                <i className="fas fa-check-circle text-primary me-1"></i>
                                URL: <b><a href={url} target="_blank" rel="noopener noreferrer">{url}</a></b>
                            </p>
                            <br />
                            <p className="text-dark">
                                <i className="fas fa-check-circle text-primary me-1"></i>
                                Email: <b><a href={`mailto:${email}`}>{email}</a></b>
                            </p>
                            <br />
                            <p className="text-dark">
                                <i className="fas fa-check-circle text-primary me-1"></i>
                                TEL: <b><a href={`tel:${tel}`}>{tel}</a></b>
                            </p>
                            <br />
                            <p className="text-dark">
                                <i className="fas fa-check-circle text-primary me-1"></i>
                                FAX: <b><a href={`tel:${fax}`}>{fax}</a></b>
                            </p>

                            {/* <!--embed map--> */}
                        </div>
                    </div>

                    <div className="row g-4 justify-content-between mb-5">
                        <div className="col-xl-5">
                            <a href="#" className="btn btn-primary rounded-pill py-3 px-5">Discover More</a>
                        </div>
                        <div className="col-xl-7 mb-5">
                            {/* Additional content here */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

CompanyProfileComponents.propTypes = {
    imageSrc: PropTypes.string.isRequired,
    companyName: PropTypes.string.isRequired,
    establishedDate: PropTypes.string.isRequired,
    capital: PropTypes.string.isRequired,
    academyLocations: PropTypes.arrayOf(PropTypes.shape({
        city: PropTypes.string.isRequired,
        address: PropTypes.string.isRequired,
        website: PropTypes.string.isRequired,
    })).isRequired,
    businessContent: PropTypes.arrayOf(PropTypes.string).isRequired,
    representative: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    tel: PropTypes.string.isRequired,
    fax: PropTypes.string.isRequired,
};

export default CompanyProfileComponents;
