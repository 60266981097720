import React from 'react';
import '../../css/Item.css';
import '../../css/bootstrap.min.css';
import '../../css/style.css';
import '../../css/main.css';
import "../../lib/animate/animate.min.css";
import "../../lib/owlcarousel/assets/owl.carousel.min.css";

function IndexCompanyTeam({ teamMembers }) {
    return (
        <div className="container-xxl py-5">
            <div className="container">
                <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                    <h6 className="section-title bg-white text-center text-primary px-3">Instructors</h6>
                    <h1 className="mb-5">Expert Instructors</h1>
                </div>
                <div className="row g-4">
                    {teamMembers.map((member, index) => (
                        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay={`${0.1 * (index + 1)}s`} key={member.name}>
                            <div className="team-item bg-light">
                                <div className="overflow-hidden">
                                    <img className="img-fluid" src={member.imageSrc} alt={member.name} />
                                </div>
                                <div className="position-relative d-flex justify-content-center" style={{ marginTop: "-23px" }}>
                                    <div className="bg-light d-flex justify-content-center pt-2 px-1">
                                        {member.facebook && <a className="btn btn-sm-square btn-primary mx-1" href={member.facebook}><i className="fab fa-facebook-f"></i></a>}
                                        {member.twitter && <a className="btn btn-sm-square btn-primary mx-1" href={member.twitter}><i className="fab fa-twitter"></i></a>}
                                        {member.instagram && <a className="btn btn-sm-square btn-primary mx-1" href={member.instagram}><i className="fab fa-instagram"></i></a>}
                                    </div>
                                </div>
                                <div className="text-center p-4">
                                    <h5 className="mb-0">{member.name}</h5>
                                    <small>{member.designation}</small>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default IndexCompanyTeam;
