import BreadCrumbs from "../components/BreadCrumbs";
import CompanyFeatures from "../components/CompanyRecruitmentaComponents/CompanyFeatures";
import HireComponents from "../components/CompanyRecruitmentaComponents/HireComponents";
import WizJob from "../components/CompanyRecruitmentaComponents/WizJob";
import Footer from "../components/Footer";
import Header from "../components/Header";
import NavBar from "../components/NavBar";
import TopNavBar from "../components/TopNavBar";
import rs1 from '../img/rs1.webp';
import rs2 from '../img/rs2.webp';
import rs3 from '../img/rs3.webp';
import rs4 from '../img/rs4.webp';

function RecruitmentPage(){
    const title = "Registration Support business";
    const breadcrumbItems = [
        { label: "Home", href: "#", isActive: false },
        { label: "Educational Services", href: "#", isActive: true }]


   
        
        const featureData = [
            {
                id: 1,
                title: "High recruitment rate and low commission",
                description: "The personnel to be introduced are students who have received the curriculum of our Wiz Academy, so there are few mismatches and the hiring rate is high. In addition, we will introduce you from the interview to joining the company at a price of 0 yen, which is cheaper than other companies. (We can also discuss the amount)",
                imageSrc: rs1,
                bullets: [
                    "Ullamco laboris nisi ut aliquip ex ea commodo consequat.",
                    "Duis aute irure dolor in reprehenderit in voluptate velit.",
                    "Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate trideta storacalaperda mastiro dolore eu fugiat nulla pariatur."
                ]
            },
            {
                id: 2,
                title: "High quality human resources",
                description: "We only introduce trainees at Wiz Academy to companies, and we will introduce human resources who are willing to do their best no matter what. Japanese level is also equivalent to Japanese business level (N2)",
                imageSrc: rs2
            },
            {
                id: 3,
                title: "Visa application / moving support",
                description: "We would like to introduce an administrative scrivener specializing in foreign work visas. The administrative scrivener will take care of the procedure. In addition, if there are students who need to move to work, we will also support you in finding a new location. (There is our partner real estate company)",
                imageSrc: rs3
            },
            {
                id: 4,
                title: "Follow-up after joining the company",
                description: "Since only students who have been educated at Wiz Academy, there will be almost no trouble after joining the company. In the unlikely event that you have a problem with a student after joining the company, you can consult and support. In the unlikely event that you quit within six months of joining the company for your own convenience, we can introduce you to a substitute student.",
                imageSrc: rs4
            }
        ];



        const hireData = {
            title: "Who would you hire?",
            description: "Human resources that can be hired are classified into three types.",
            items: [
                {
                    title: "[Job hunters]",
                    description: "The students of our school (Wiz Academy) are not only expected to graduate in April, there are also students who can be hired at the timing of hiring companies.",
                    icon: "bi-search"
                },
                {
                    title: "[Job changer]",
                    description: "You can also hire employees who have working experience in Japanese companies.",
                    icon: "bi-bookmark"
                },
                {
                    title: "[Human resources from overseas]",
                    description: "You can also hire human resources from overseas, who have full-time work experience in their home country or Japan and have returned to their home countries.",
                    icon: "bi-book"
                }
            ],
            youtubeLink: "#"
        };

    return(<>
        <TopNavBar />
        <NavBar />
        <BreadCrumbs title={title} breadcrumbItems={breadcrumbItems} />
      
       <WizJob />
       <CompanyFeatures features={featureData} />
       <HireComponents 
                title={hireData.title}
                description={hireData.description}
                items={hireData.items}
                youtubeLink={hireData.youtubeLink}
            />
        <Footer />
        </>

    )
}
export default RecruitmentPage;