import Footer from '../components/Footer';
import NavBar from '../components/NavBar';
import Header from '../components/Header';
import Four04 from '../components/Four04';

function Error404Page() {
  return (
    <>
    <NavBar />
    <Header />
    <Four04 />
    <Footer />
    </>
   
  );
}

export default Error404Page;
