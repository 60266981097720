import BreadCrumbs from "../components/BreadCrumbs";
import Footer from "../components/Footer";
import Header from "../components/Header";
import NavBar from "../components/NavBar";
import Benefits from "../components/RecruitmentServiceBusinessComponents/Benefits";
import CompanyJob from "../components/RecruitmentServiceBusinessComponents/CompanyJob";
import FAQ from "../components/RecruitmentServiceBusinessComponents/FAQ";
import FlowOfHiring from "../components/RecruitmentServiceBusinessComponents/FlowOfHiring";
import Pricing from "../components/RecruitmentServiceBusinessComponents/Pricing";
import RegisteredPerson from "../components/RecruitmentServiceBusinessComponents/RegisteredPerson";
import SpecifiedSkilledWorker from "../components/RecruitmentServiceBusinessComponents/SpecifiedSkilledWorkers";
import SpecifiedSkills from "../components/RecruitmentServiceBusinessComponents/SpecifiedSkills";
import SupportedSkill from "../components/RecruitmentServiceBusinessComponents/SupportedSkill";
import TopNavBar from "../components/TopNavBar";

function RsbPage(){
    const title = "specified skilled workers";
    const breadcrumbItems = [
        { label: "Home", href: "#", isActive: false },
        { label: "Educational Services", href: "#", isActive: true }]
    return(
        <>
        <TopNavBar />
        <NavBar />
        <BreadCrumbs title={title} breadcrumbItems={breadcrumbItems} />
       
        <CompanyJob />
        <SpecifiedSkills />
      
        <SupportedSkill />
        <SpecifiedSkilledWorker />
    <Benefits/>
  <RegisteredPerson />
  <FlowOfHiring />

  <Pricing />
  <FAQ />
  <Footer />
        </>
    )

}
export default RsbPage;