import React from 'react';
import blog1 from '../../img/blog/blog-1.jpg';
import blog2 from '../../img/blog/blog-2.jpg';
import blog3 from '../../img/blog/blog-3.jpg';

function RecentBlogPost() {
    const blogPosts = [
        {
            id: 1,
            img: blog1,
            date: 'December 12',
            title: 'Eum ad dolor et. Autem aut fugiat debitis',
            author: 'Julia Parker',
            category: 'Politics',
            link: 'blog-details.html',
            aosDelay: '100'
        },
        {
            id: 2,
            img: blog2,
            date: 'July 17',
            title: 'Et repellendus molestiae qui est sed omnis',
            author: 'Mario Douglas',
            category: 'Sports',
            link: 'blog-details.html',
            aosDelay: '200'
        },
        {
            id: 3,
            img: blog3,
            date: 'September 05',
            title: 'Quia assumenda est et veritati tirana ploder',
            author: 'Lisa Hunter',
            category: 'Economics',
            link: 'blog-details.html',
            aosDelay: '300'
        }
    ];

    return (
        <section id="recent-blog-posts" className="recent-blog-posts">
            <div className="container" data-aos="fade-up">
                <div className="section-header">
                    <h2>Recent Blog Posts</h2>
                    <p>In commodi voluptatem excepturi quaerat nihil error autem voluptate ut et officia consequuntu</p>
                </div>
                <div className="row gy-5">
                    {blogPosts.map(post => (
                        <div key={post.id} className="col-xl-4 col-md-6" data-aos="fade-up" data-aos-delay={post.aosDelay}>
                            <div className="post-item position-relative h-100">
                                <div className="post-img position-relative overflow-hidden">
                                    <img src={post.img} className="img-fluid" alt="" />
                                    <span className="post-date">{post.date}</span>
                                </div>
                                <div className="post-content d-flex flex-column">
                                    <h3 className="post-title">{post.title}</h3>
                                    <div className="meta d-flex align-items-center">
                                        <div className="d-flex align-items-center">
                                            <i className="bi bi-person"></i> <span className="ps-2">{post.author}</span>
                                        </div>
                                        <span className="px-3 text-black-50">/</span>
                                        <div className="d-flex align-items-center">
                                            <i className="bi bi-folder2"></i> <span className="ps-2">{post.category}</span>
                                        </div>
                                    </div>
                                    <hr />
                                    <a href={post.link} className="readmore stretched-link">
                                        <span>Read More</span><i className="bi bi-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default RecentBlogPost;
