import React from 'react';
import '../../css/Item.css';
import '../../css/bootstrap.min.css';
import '../../css/style.css';
import '../../css/main.css';
import "../../lib/animate/animate.min.css";
import "../../lib/owlcarousel/assets/owl.carousel.min.css";

function CompanyFeatures({ features }) {
    return (
        <section id="features" className="features section-bg">
            <div className="container-xxl py-5 category">
                <div className="container">
                    <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                        <h1 className="mb-5">Wiz Academy's Student</h1>
                        <h6 className="section-title bg-white text-center text-primary px-3"></h6>
                    </div>
                </div>
                <div className="container" data-aos="fade-up">
                    <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                        <ul className="nav nav-tabs row g-2 d-flex">
                            {features.map((feature, index) => (
                                <li className="nav-item col-3" key={feature.id}>
                                    <a className={`nav-link ${index === 0 ? 'active show' : ''}`} data-bs-toggle="tab" data-bs-target={`#tab-${feature.id}`}>
                                        <h4>{feature.title}</h4>
                                    </a>
                                </li>
                            ))}
                        </ul>
                        <div className="tab-content">
                            {features.map((feature, index) => (
                                <div className={`tab-pane ${index === 0 ? 'active show' : ''}`} id={`tab-${feature.id}`} key={feature.id}>
                                    <div className="row">
                                        <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 d-flex flex-column justify-content-center">
                                            <p className="fst-regular">{feature.description}</p>
                                            {feature.bullets && (
                                                <ul>
                                                    {feature.bullets.map((bullet, bulletIndex) => (
                                                        <li key={bulletIndex}>
                                                            <i className="bi bi-check2-all"></i> {bullet}
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </div>
                                        <div className="col-lg-6 order-1 order-lg-2 text-center">
                                            <img src={feature.imageSrc} alt="" style={{ borderRadius: "10px" }} className="img-fluid" />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CompanyFeatures;
