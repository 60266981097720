import React from 'react';
import asian from '../../img/young-asian-businesswoman-using-mobile-smartphone-young-female-professional-city-front-big-building.jpg';
import sideview from '../../img/side-view-smiley-woman-train-station.jpg';
import female from '../../img/female-student-sitting-stairs-read-book.jpg';

function ActivitySupport() {
    const activitySupportData = [
        {
            title: 'Employment Activity Support School for Foreigner Wiz Academy',
            description: 'Wiz Academy is an employment activity support school for foreigner run by Wiz Global Co., Ltd. with the goal of solving the problem of human resources shortage in Japan. We offer classes in Japanese business manners, customer service, Japanese, English, etc. with our own curriculum. So that, students can work for a long time at a Japanese company. In addition, we also offer courses for specific skill test preparation and TOEIC preparation according to the wishes of students.',
            buttonText1: 'Get started',
            buttonText2: 'Learn more',
            imageSrc: asian,
            imageAlt: 'Employment Activity Support'
        },
        {
            title: 'Foreign Human Resources Recruitment Support Service Wiz Job',
            description: 'Wiz Academy is an employment activity support school for foreigner run by Wiz Global Co., Ltd. with the goal of solving the problem of human resources shortage in Japan. We offer classes in Japanese business manners, customer service, Japanese, English, etc. with our own curriculum. So that, students can work for a long time at a Japanese company. In addition, we also offer courses for specific skill test preparation and TOEIC preparation according to the wishes of students.',
            buttonText1: 'Get started',
            buttonText2: 'Learn more',
            imageSrc: sideview,
            imageAlt: 'Foreign Human Resources Recruitment Support'
        },
        {
            title: 'Foreign Human Resources Recruitment Support Service Wiz Job',
            description: 'Wiz Job is an introduction of foreign human resources operated by Wiz Global Co., Ltd. with the goal of solving the problem of human resources shortage in Japan. For students who have taken an employment activity support course at our Wiz Academy, we introduce them to Japanese companies as full-time employees or contract employees.',
            buttonText1: 'Get started',
            buttonText2: 'Learn more',
            imageSrc: female,
            imageAlt: 'Human Resources Recruitment Support'
        }
    ];

    return (
        <>
            {activitySupportData.map((item, index) => (
                <div key={index} className="text-center wow fadeInUp" data-wow-delay="0.1s">
                    <div className="container">
                        <div className="row flex-center">
                            <div className={`col-md-6 ${index % 2 === 0 ? 'text-center text-md-start' : ''}`}>
                                <b className="fw-normal fs-3" data-zanim-xs='{"delay":0.3}' data-zanim-trigger="scroll">{item.title}</b>
                                <hr />
                                <p className="fs-0 pe-xl-8" data-zanim-xs='{"delay":0.5}' data-zanim-trigger="scroll">{item.description}</p>
                                <div className="d-flex justify-content-center align-item-center my-3 mt-2">
                                    <button className="btn btn-sm btn-primary my-4 me-1" href="#!" role="button">{item.buttonText1}</button>
                                    <a className="btn btn-sm my-2 btn-default" href="#" role="button">{item.buttonText2}<i className="fas fa-arrow-right ms-2"></i></a>
                                </div>
                            </div>
                            <div className="col-md-6 mb-4">
                                <img className="w-100" src={item.imageSrc} alt={item.imageAlt} />
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
}

export default ActivitySupport;
